// src/components/ChatbotLoader/ChatbotLoader.tsx
import React, { useEffect } from "react";

const ChatbotLoader = () => {
  useEffect(() => {
    // Check if the script is already added to prevent duplicates
    console.log("ChatbotLoader mounted");
    if (!document.getElementById("voiceflow-chatbot")) {
      const script = document.createElement("script");
      script.id = "voiceflow-chatbot";
      script.type = "text/javascript";
      script.innerHTML = `
        (function(d, t) {
          var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
          v.onload = function() {
            window.voiceflow.chat.load({
              verify: { projectID: '66e82f8c9c9e8a08feb4aa41' },
              url: 'https://general-runtime.voiceflow.com',
              versionID: 'production'
            });
          };
          v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
        })(document, 'script');
      `;
      document.body.appendChild(script);
    }
  }, []);

  return null; // This component doesn't render any JSX
};

export default ChatbotLoader;
