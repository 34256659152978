import { Button, Grid, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordSchema } from "utils/ValidatorSchema";
import { changePasswordForm } from "types";
import { updateData } from "services/CommonServices";
import { MASTER_ENDPOINT } from "utils/Constant";
import CustomInput from "components/CustomInput/customInput";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const Changepassword = () => {
  const { handleSubmit, control, formState, reset, watch } =
    useForm<changePasswordForm>({
      defaultValues: {
        confirmPassword: "",
        currentPassword: "",
        newPassword: "",
      },
      reValidateMode: "onChange",
      resolver: yupResolver(changePasswordSchema),
    });

  const submitForm = (data: any) => {
    updateData("", data, MASTER_ENDPOINT.Changepassword, true)
      .then(() => {
        reset();
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={"div"}
          className="d-flex-a flex-sb bottom-border pb-24"
        >
          <Typography className="commontitle">Change Password</Typography>
          <Typography component={"div"} className="inline-flex">
            <Button className="cancel-btn btn-2r" onClick={() => reset()}>
              Cancel
            </Button>
            <Button className="save-btn" onClick={handleSubmit(submitForm)}>
              Save
            </Button>
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={"div"}
          className="form-box"
          sx={{ padding: "24px !important" }}
        >
          <Grid container>
            <Grid item md={6} sm={12} xs={12}>
              <form className="custom-form">
                <Grid container rowSpacing={2.5}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="currentPassword"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <>
                            <CustomInput
                              label="Enter Old Password"
                              type="password"
                              className="custom-input"
                              onChange={onChange}
                              value={value}
                              name={name}
                              ref={ref}
                              error={formState.errors.currentPassword?.message}
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="newPassword"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label="Enter New Password"
                            type="password"
                            className="custom-input"
                            onChange={onChange}
                            value={value}
                            name={name}
                            ref={ref}
                            error={formState.errors.newPassword?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="confirmPassword"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label="Confirm New Password"
                            type="password"
                            className="custom-input"
                            onChange={onChange}
                            value={value}
                            name={name}
                            ref={ref}
                            error={formState.errors.confirmPassword?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography
                  component={"div"}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography component={"div"} className="in-flex mb-4 mt-12">
                    {/[A-Z]+/.test(watch("newPassword")) ? (
                      <DoneIcon className="tick-icon" />
                    ) : (
                      <CloseOutlinedIcon
                        fontSize={"small"}
                        sx={{ fill: "red" }}
                      />
                    )}
                    <Typography className="c-note">Upper Case</Typography>
                  </Typography>

                  <Typography component={"div"} className="in-flex mb-4">
                    {/[0-9]+/.test(watch("newPassword")) ? (
                      <DoneIcon className="tick-icon" />
                    ) : (
                      <CloseOutlinedIcon
                        fontSize={"small"}
                        sx={{ fill: "red" }}
                      />
                    )}
                    <Typography className="c-note">Number</Typography>
                  </Typography>

                  <Typography component={"div"} className="in-flex mb-4">
                    {/[*@!$#%&()^~|{}]+/.test(watch("newPassword")) ? (
                      <DoneIcon className="tick-icon" />
                    ) : (
                      <CloseOutlinedIcon
                        fontSize={"small"}
                        sx={{ fill: "red" }}
                      />
                    )}
                    <Typography className="c-note">
                      Special Charactors
                    </Typography>
                  </Typography>

                  <Typography component={"div"} className="in-flex">
                    {watch("newPassword").length >= 8 ? (
                      <DoneIcon className="tick-icon" />
                    ) : (
                      <CloseOutlinedIcon
                        fontSize={"small"}
                        sx={{ fill: "red" }}
                      />
                    )}
                    <Typography className="c-note">
                      Must Be Atleast 8 Charactors
                    </Typography>
                  </Typography>
                </Typography>
              </form>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Changepassword;
