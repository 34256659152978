import React, { useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// import ArrowDown from 'assets/png/arrow_down.svg';
import { getAllListData } from "services/CommonServices";
import { MASTER_ENDPOINT } from "utils/Constant";
import CustomSelect from "components/CustomSelect";
import { StandardType } from "utils/ArrayContans";
import Loader from "components/Loader";
import { roundOfData1 } from "utils/Config";

const Standard = () => {
  const [tableData, setTableData] = useState<any>({
    loader: false,
    seedData: [],
    dualData: [],
    strawData: [],
  });
  const [standData, setStandData] = useState<string>("all");

  useEffect(() => {
    getList(standData);
  }, [standData]);

  const getList = (standData: string) => {
    setTableData({
      loader: true,
      seedData: [],
      dualData: [],
      strawData: [],
    });
    getAllListData(
      `${MASTER_ENDPOINT.Standards}?order=asc&sortby=id&modelType=${
        standData !== "all" ? standData : ""
      }`
    ).then((res: any) => {
      setTableData({
        ...tableData,
        loader: false,
        dualData: res?.data?.dualPurpose || [],
        seedData: res?.data?.seedPurpose || [],
        strawData: res?.data?.strawPurpose || [],
      });
    });
  };

  const tableHeadData = (type: string) => {
    return (
      <TableHead>
        <TableRow>
          <TableCell colSpan={7}>
            <Typography component={"div"} className="d-flex-a">
              <Typography className="th-title">
                {type === "dual"
                  ? "Dual Purpose"
                  : type === "seed"
                  ? "Hemp Oil"
                  : "Hemp Fibre"}{" "}
                Model
              </Typography>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component={"div"} className="d-flex-a">
              {/* <Typography component={'div'} className='arrow-box'>
                <img src={ArrowDown} alt='arrow' className='d-arrow' />
                <img src={ArrowDown} alt='arrow' className='u-arrow' />
              </Typography> */}
              <Typography className="th-title">Category</Typography>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography component={"div"} className="d-flex-a">
              {/* <Typography component={'div'} className='arrow-box'>
                <img src={ArrowDown} alt='arrow' className='d-arrow' />
                <img src={ArrowDown} alt='arrow' className='u-arrow' />
              </Typography> */}
              <Typography className="th-title">Sub-Category</Typography>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography component={"div"} className="d-flex-a">
              {/* <Typography component={'div'} className='arrow-box'>
                <img src={ArrowDown} alt='arrow' className='d-arrow' />
                <img src={ArrowDown} alt='arrow' className='u-arrow' />
              </Typography> */}
              <Typography className="th-title">Variable</Typography>
            </Typography>
          </TableCell>
          <TableCell>Values</TableCell>
          <TableCell>Units</TableCell>
          {/* <TableCell>Value</TableCell>
          <TableCell>Units</TableCell> */}
        </TableRow>
      </TableHead>
    );
  };

  const tableBodyData = (item: any, index: number) => {
    if ((!item?.defaultHectare || !item?.defaultAcre) && !item?.isHide) {
      return (
        <TableRow key={index}>
          <TableCell>{item?.categoryView}</TableCell>
          <TableCell>{item?.subCategoryView}</TableCell>
          <TableCell>
            {item?.variablesView === "Fertilizer P2O5" ? (
              <>
                Fertilizer P<sub>2</sub>O<sub>5</sub>
              </>
            ) : item?.variablesView === "Fertilizer K2O" ? (
              <>
                Fertilizer K<sub>2</sub>O
              </>
            ) : (
              item?.variablesView
            )}
          </TableCell>
          {!item?.defaultAcre ? (
            <>
              <TableCell>{roundOfData1(item?.acreValue)}</TableCell>
              <TableCell sx={{ textTransform: "capitalize" }}>
                {item?.acreUnit}
              </TableCell>
            </>
          ) : !item?.defaultHectare ? (
            <>
              <TableCell>{roundOfData1(item?.hectareValue)}</TableCell>
              <TableCell sx={{ textTransform: "capitalize" }}>
                {item?.hectareUnit}
              </TableCell>
            </>
          ) : (
            <>
              <TableCell>{roundOfData1(Number(item?.acreValue))}</TableCell>
              <TableCell sx={{ textTransform: "capitalize" }}>
                {item?.acreUnit}
              </TableCell>
            </>
          )}
        </TableRow>
      );
    } else {
      return <Typography key={index} />;
    }
  };
  return (
    <Grid container>
      <Loader openLoader={tableData.loader} />
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={"div"}
          className="d-flex-a flex-sb pb-24 bottom-border mb-24"
        >
          <Typography className="commontitle">Parameters</Typography>
          <Typography component={"div"} className="custom-form">
            <FormControl
              fullWidth
              className="custom-input"
              sx={{ backgroundColor: "#ffffff", width: "200px !important" }}
            >
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <CustomSelect
                onChange={(e) => setStandData(e?.target?.value)}
                value={standData}
                options={StandardType}
                labelKey="label"
                valueKey="value"
              />
            </FormControl>
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={"div"} className="custom-table standard-sticky">
          <TableContainer
            component={Paper}
            sx={{ maxHeight: "calc(100vh - 256px)" }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 1280 }}
              aria-label="simple table"
            >
              {tableData?.seedData?.length ? (
                <>
                  {tableHeadData("seed")}
                  <TableBody>
                    {tableData?.seedData.map((item: any, index: number) => {
                      return tableBodyData(item, index);
                    })}
                  </TableBody>
                </>
              ) : (
                <Typography />
              )}
              {tableData?.dualData?.length ? (
                <>
                  {tableHeadData("dual")}
                  <TableBody>
                    {tableData?.dualData.map((item: any, index: number) => {
                      return tableBodyData(item, index);
                    })}
                  </TableBody>
                </>
              ) : (
                <Typography />
              )}
              {tableData?.strawData?.length ? (
                <>
                  {tableHeadData("straw")}
                  <TableBody>
                    {tableData?.strawData.map((item: any, index: number) => {
                      return tableBodyData(item, index);
                    })}
                  </TableBody>
                </>
              ) : (
                <Typography />
              )}
            </Table>
          </TableContainer>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Standard;
