import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Typography } from "@mui/material";
import CustomInput from "components/CustomInput/customInput";
import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getAllListData, updateData } from "services/CommonServices";
import { orgForm } from "types";
import { MASTER_ENDPOINT } from "utils/Constant";
import { orgSchema } from "utils/ValidatorSchema";
// import UserPng from 'assets/png/user.png';
import orgoLogo from "assets/png/orgoLogo.png";
import { useDispatch } from "react-redux";
import { USER_ORGANIZATION_LOGO } from "store/types/UserTypes";
import Storage from "utils/Storage";

const Oraganization = () => {
  const dispatch: any = useDispatch();
  const [orgData, setOrgData] = useState<any>({});
  const [loader, setLoader] = React.useState(false);
  const [disabled, setDisabled] = useState<any>(true);
  const [orgLogo, setOrgLogo] = useState<any>("");
  const [localImage, setLocalImage] = React.useState<any>("");
  const { handleSubmit, control, formState, setValue, reset } =
    useForm<orgForm>({
      mode: "all",
      defaultValues: {
        name: "",
        address: "",
        email: "",
        website: "",
      },
      reValidateMode: "onChange",
      resolver: yupResolver<any>(orgSchema),
    });

  const submitForm = (data: any) => {
    if (orgLogo?.name) {
      const files = orgLogo?.name.split(".").pop();
      if (!["jpeg", "png", "jpg"].includes(files)) {
        toast.error("You can Upload only jpeg, jpg, png");
        return true;
      }
    }
    setLoader(true);
    const formData = new FormData();
    if (orgLogo.name) {
      formData.append("file", orgLogo);
    }
    formData.append("name", data["name"]);
    formData.append("email", data["email"]);
    formData.append("address", data["address"]);
    formData.append("website", data["website"]);
    updateData(orgData?._id, formData, MASTER_ENDPOINT.Organization, true, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (resp: any) => {
        if (resp?.fileId) {
          // await Storage.normalRemoveItem(Storage.KEYS.ORGANIZATION_LOGO);
          await Storage.removeItem(Storage.KEYS.ORGANIZATION_LOGO);
          await Storage.setItem(Storage.KEYS.ORGANIZATION_LOGO, resp?.fileId);
          dispatch({
            type: USER_ORGANIZATION_LOGO,
            payload: resp?.fileId,
          });
        }
        setLoader(false);
        setDisabled(true);
        setOrgLogo("");
        setLocalImage("");
        getOrg();
      })
      .catch((err: any) => console.log(err));
  };

  const getOrg = () => {
    setLoader(true);
    getAllListData(`${MASTER_ENDPOINT.Organization}`)
      .then((res) => {
        const respData = res.data[0];
        setOrgData(respData);
        setValue("name", respData?.name);
        setValue("email", respData?.email ? respData?.email : "");
        setValue("address", respData?.address ? respData?.address : "");
        setValue("website", respData?.website ? respData?.website : "");
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.warn(err);
      });
  };

  const orgCancel = () => {
    reset();
    getOrg();
    setDisabled(true);
  };

  useEffect(() => {
    getOrg();
  }, []);

  return (
    <Grid container>
      {<Loader openLoader={loader} />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={"div"}
          className="d-flex-a flex-sb bottom-border pb-24"
        >
          <Typography className="commontitle">My Organization</Typography>
          <Typography component={"div"} className="inline-flex">
            {disabled ? (
              <Button
                className="cancel-btn btn-2r"
                onClick={() => setDisabled(false)}
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  className="cancel-btn btn-2r"
                  onClick={() => orgCancel()}
                >
                  cancel
                </Button>
                <Button
                  className="save-btn"
                  onClick={handleSubmit(submitForm)}
                  disabled={disabled}
                >
                  Save
                </Button>
              </>
            )}
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={"div"} className="form-box">
          <Grid container rowSpacing={2.5}>
            <Grid item md={7.5} sm={12} xs={12}>
              <form className="custom-form">
                <Grid container columnSpacing={4} rowSpacing={2.5}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="name"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label="Organization Name *"
                            className="custom-input"
                            onChange={onChange}
                            value={value}
                            name={name}
                            ref={ref}
                            disabled={disabled}
                            error={formState.errors.name?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="address"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label="Address"
                            className="custom-input"
                            onChange={onChange}
                            value={value}
                            name={name}
                            ref={ref}
                            isTextArea={true}
                            minRows={4}
                            disabled={disabled}
                            error={formState.errors.address?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  {/* <Grid item md={6} sm={12}>
                    <TextField label='Phone number' className='custom-input' />
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <TextField label='Fax number' className='custom-input' />
                  </Grid> */}
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="email"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label="Email address *"
                            className="custom-input"
                            onChange={onChange}
                            value={value}
                            name={name}
                            ref={ref}
                            disabled={disabled}
                            error={formState.errors.email?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="website"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label="Website"
                            className="custom-input"
                            onChange={onChange}
                            value={value}
                            name={name}
                            ref={ref}
                            disabled={disabled}
                            error={formState.errors.website?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid
              item
              md={4.5}
              sm={12}
              xs={12}
              className="d-flex-ja flex-column"
            >
              <Typography className="org-title">Organization Logo</Typography>
              <img
                className="org-profile"
                src={
                  localImage
                    ? localImage
                    : orgData?.logo?._id
                    ? `${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.files}?id=${orgData?.logo?._id}`
                    : orgoLogo
                }
                alt="IndHemp"
              />
              <Button className="org-btn" component="label" disabled={disabled}>
                Upload Picture
                <input
                  accept="image/*"
                  hidden
                  type="file"
                  onChange={(e: any) => {
                    if (e.target.files[0]) {
                      setOrgLogo(e.target.files[0]);
                      setLocalImage(URL.createObjectURL(e.target.files[0]));
                    }
                  }}
                />
              </Button>
              {/* <Typography className='org-remove'>Remove picture</Typography> */}
              <Typography className="org-support">
                supported formats : jpeg, jpg, png{" "}
              </Typography>
              <Typography className="org-support">
                Maximum size : 5MB
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Oraganization;
