import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableLoader from "components/TableLoader";
import { Typography } from "@mui/material";
import { roundOfData, roundOfData1 } from "utils/Config";
import { CHART_COLOR } from "utils/Constant";

interface ListProps {
  data: any;
  loader: boolean;
}

CHART_COLOR.redColor = "#DA9694";

const styleData: any = {
  corbonSticky: {
    position: "sticky",
    left: 0,
    background: "white",
  },
  activitySticky: {
    position: "sticky",
    left: 0,
    minWidth: 250,
    background: "white",
  },
  valueSticky: {
    // position: 'sticky',
    // left: 250,
    minWidth: 150,
    background: "white",
  },
  kgSmall: {
    minWidth: 150,
    background: "white",
  },
  kgMedium: {
    minWidth: 180,
    background: "white",
  },
  kgLarge: {
    minWidth: 200,
    background: "white",
  },
};

const CorbonFootPrintWithLca = (props: ListProps) => {
  const { data, loader } = props;
  return (
    <TableContainer
      component={Paper}
      sx={{
        "&::-webkit-scrollbar": {
          height: "8px",
          width: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#cfcfcf",
          borderRadius: 2,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#fffff",
          borderRadius: 2,
        },
      }}
    >
      <Table
        stickyHeader
        className="result-sticky-head first-border"
        sx={{ width: "100%" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className="blue-header"
              style={styleData.corbonSticky}
              colSpan={2}
            >
              <Typography
                component={"div"}
                sx={{ padding: "10px !important", fontWeight: "bold" }}
              >
                Carbon Footprint
              </Typography>
            </TableCell>
            <TableCell className="blue-header" align="center" colSpan={8}>
              <Typography
                component={"div"}
                sx={{ padding: "10px !important", fontWeight: "bold" }}
              >
                LCA - Cradle to Gate
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={styleData.activitySticky}
              align="center"
              className="ligt-green"
            >
              Activities
            </TableCell>
            <TableCell
              style={styleData.valueSticky}
              align="center"
              className="ligt-green"
            >
              Value
            </TableCell>
            <TableCell style={styleData.kgSmall} align="center">
              Kg CO<sub>2</sub> eq/ton
            </TableCell>
            <TableCell style={styleData.kgMedium} align="center">
              Kg CO<sub>2</sub> eq/ton
            </TableCell>
            <TableCell style={styleData.kgMedium} align="center">
              Kg CO<sub>2</sub> eq/ton
            </TableCell>
            <TableCell style={styleData.kgLarge} align="center">
              Kg CO<sub>2</sub> eq/ton
            </TableCell>
            <TableCell style={styleData.kgLarge} align="center">
              Kg CO<sub>2</sub> eq/ton
            </TableCell>
            <TableCell style={styleData.kgLarge} align="center">
              Kg CO<sub>2</sub> eq/ton
            </TableCell>
            {/*<TableCell style={styleData.kgMedium} align="center">*/}
            {/*  Kg CO<sub>2</sub> eq/ton*/}
            {/*</TableCell>*/}
            {/*<TableCell style={styleData.kgMedium} align="center">*/}
            {/*  Kg CO<sub>2</sub> eq/ton*/}
            {/*</TableCell>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {loader ? (
            <TableLoader colsSpan={8} />
          ) : (
            <>
              <TableRow>
                <TableCell
                  style={styleData.corbonSticky}
                  align="center"
                  className="ligt-green"
                >
                  PRE-FARM
                </TableCell>
                <TableCell
                  style={styleData.valueSticky}
                  align="center"
                  className="ligt-green"
                >
                  Kg CO<sub>2</sub> eq/year
                </TableCell>
                <TableCell
                  style={styleData.kgSmall}
                  align="center"
                  className="small-green"
                >
                  Oil
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Meal
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Hull
                </TableCell>
                <TableCell
                  style={styleData.kgLarge}
                  align="center"
                  className="small-green"
                >
                  Bast Fiber
                </TableCell>
                {/*<TableCell*/}
                {/*  style={styleData.kgLarge}*/}
                {/*  align="center"*/}
                {/*  className="small-green"*/}
                {/*>*/}
                {/*  Long Bast Fiber*/}
                {/*</TableCell>*/}
                {/*<TableCell*/}
                {/*  style={styleData.kgLarge}*/}
                {/*  align="center"*/}
                {/*  className="small-green"*/}
                {/*>*/}
                {/*  Short Bast Fiber*/}
                {/*</TableCell>*/}
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Hurd
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Dust
                </TableCell>
              </TableRow>
              {data?.resultAndCarbonFootPrint?.carbonFootPrint?.preFarm &&
              data?.resultAndCarbonFootPrint?.carbonFootPrint?.preFarm
                ?.length ? (
                data?.resultAndCarbonFootPrint?.carbonFootPrint?.preFarm
                  .sort(
                    (a: any, b: any) => parseInt(a.order) - parseInt(b.order)
                  )
                  .map((result: any, index: number) => {
                    const lcaOil = data?.lcaOilResult?.preFarm;
                    const lcaCake = data?.lcaCakeResult?.preFarm;
                    const lcaHull = data?.lcaHullResult?.preFarm;
                    const lcaBastFiber = data?.lcaBastFiberResult?.preFarm;
                    // const lcaLongBastFiber =
                    //   data?.lcaLongBastFiberesult?.preFarm;
                    // const lcaShortBastFiber =
                    //   data?.lcaShortBastFiberesult?.preFarm;
                    const lcaHurd = data?.lcaHurdResult?.preFarm;
                    const lcaDust = data?.lcaDustResult?.preFarm;
                    let tableColor = "#fff";
                    if (["emissionsPre-farm"].includes(result?.key)) {
                      tableColor = CHART_COLOR.redColor;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            ...styleData.activitySticky,
                            background: tableColor,
                            textTransform: "capitalize",
                          }}
                        >
                          {result?.key === "productionOfFertilizerP2O5" ? (
                            <span>
                              Production of fertilizer P<sub>2</sub>O
                              <sub>5</sub>
                            </span>
                          ) : result?.key === "productionOfFertilizerK2O" ? (
                            <span>
                              Production of fertilizer K<sub>2</sub>O
                            </span>
                          ) : result?.key === "productionOfLimeCaCO3" ? (
                            <span>
                              Production of lime CaCO<sub>3</sub>
                            </span>
                          ) : (
                            result?.activities
                          )}
                        </TableCell>
                        {/* <TableCell style={{ ...styleData.unitSticky, background: tableColor }}>
                          {result?.unit}
                        </TableCell> */}
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.valueSticky,
                            background: tableColor,
                          }}
                        >
                          {roundOfData(result?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgSmall,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaOil?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaCake?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaHull?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgLarge,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaBastFiber?.[index]?.value)}
                        </TableCell>
                        {/*<TableCell*/}
                        {/*  align="center"*/}
                        {/*  style={{*/}
                        {/*    ...styleData.kgLarge,*/}
                        {/*    background: tableColor,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {roundOfData1(lcaLongBastFiber?.[index]?.value)}*/}
                        {/*</TableCell>*/}
                        {/*<TableCell*/}
                        {/*  align="center"*/}
                        {/*  style={{*/}
                        {/*    ...styleData.kgLarge,*/}
                        {/*    background: tableColor,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {roundOfData1(lcaShortBastFiber?.[index]?.value)}*/}
                        {/*</TableCell>*/}
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaHurd?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaDust?.[index]?.value)}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <Typography />
              )}
              <TableRow>
                <TableCell
                  style={styleData.corbonSticky}
                  align="center"
                  className="ligt-green"
                >
                  ON-FARM
                </TableCell>
                <TableCell
                  style={styleData.valueSticky}
                  align="center"
                  className="ligt-green"
                >
                  Kg CO<sub>2</sub> eq/year
                </TableCell>
                <TableCell
                  style={styleData.kgSmall}
                  align="center"
                  className="small-green"
                >
                  Oil
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Meal
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Hull
                </TableCell>
                <TableCell
                  style={styleData.kgLarge}
                  align="center"
                  className="small-green"
                >
                  Bast Fiber
                </TableCell>
                {/*<TableCell*/}
                {/*  style={styleData.kgLarge}*/}
                {/*  align="center"*/}
                {/*  className="small-green"*/}
                {/*>*/}
                {/*  Long Bast Fiber*/}
                {/*</TableCell>*/}
                {/*<TableCell*/}
                {/*  style={styleData.kgLarge}*/}
                {/*  align="center"*/}
                {/*  className="small-green"*/}
                {/*>*/}
                {/*  Short Bast Fiber*/}
                {/*</TableCell>*/}
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Hurd
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Dust
                </TableCell>
              </TableRow>
              {data?.resultAndCarbonFootPrint?.carbonFootPrint?.onFarm &&
              data?.resultAndCarbonFootPrint?.carbonFootPrint?.onFarm
                ?.length ? (
                data?.resultAndCarbonFootPrint?.carbonFootPrint?.onFarm
                  .sort(
                    (a: any, b: any) => parseInt(a.order) - parseInt(b.order)
                  )
                  .map((result: any, index: number) => {
                    const lcaOil = data?.lcaOilResult?.onFarm;
                    const lcaCake = data?.lcaCakeResult?.onFarm;
                    const lcaHull = data?.lcaHullResult?.onFarm;
                    const lcaBastFiber = data?.lcaBastFiberResult?.onFarm;
                    // const lcaLongBastFiber =
                    //   data?.lcaLongBastFiberesult?.onFarm;
                    // const lcaShortBastFiber =
                    //   data?.lcaShortBastFiberesult?.onFarm;
                    const lcaHurd = data?.lcaHurdResult?.onFarm;
                    const lcaDust = data?.lcaDustResult?.onFarm;
                    let tableColor = "#fff";
                    if (["emissionsPOn-farm"].includes(result?.key)) {
                      tableColor = CHART_COLOR.redColor;
                    }
                    if (["co2HempSEQUESTRATION"].includes(result?.key)) {
                      tableColor = CHART_COLOR.greenColor;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            ...styleData.activitySticky,
                            background: tableColor,
                            textTransform: "capitalize",
                          }}
                        >
                          {result?.activities}
                        </TableCell>
                        {/* <TableCell style={{ ...styleData.unitSticky, background: tableColor }}>
                          {result?.unit}
                        </TableCell> */}
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.valueSticky,
                            background: tableColor,
                          }}
                        >
                          {roundOfData(result?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgSmall,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaOil?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaCake?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaHull?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgLarge,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaBastFiber?.[index]?.value)}
                        </TableCell>
                        {/*<TableCell*/}
                        {/*  align="center"*/}
                        {/*  style={{*/}
                        {/*    ...styleData.kgLarge,*/}
                        {/*    background: tableColor,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {roundOfData1(lcaLongBastFiber?.[index]?.value)}*/}
                        {/*</TableCell>*/}
                        {/*<TableCell*/}
                        {/*  align="center"*/}
                        {/*  style={{*/}
                        {/*    ...styleData.kgLarge,*/}
                        {/*    background: tableColor,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {roundOfData1(lcaShortBastFiber?.[index]?.value)}*/}
                        {/*</TableCell>*/}
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaHurd?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaDust?.[index]?.value)}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <Typography />
              )}
              <TableRow>
                <TableCell
                  style={styleData.corbonSticky}
                  align="center"
                  className="ligt-green"
                >
                  ON-PROCESS
                </TableCell>
                <TableCell
                  style={styleData.valueSticky}
                  align="center"
                  className="ligt-green"
                >
                  Kg CO<sub>2</sub> eq/year
                </TableCell>
                <TableCell
                  style={styleData.kgSmall}
                  align="center"
                  className="small-green"
                >
                  Oil
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Meal
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Hull
                </TableCell>
                <TableCell
                  style={styleData.kgLarge}
                  align="center"
                  className="small-green"
                >
                  Bast Fiber
                </TableCell>
                {/*<TableCell*/}
                {/*  style={styleData.kgLarge}*/}
                {/*  align="center"*/}
                {/*  className="small-green"*/}
                {/*>*/}
                {/*  Long Bast Fiber*/}
                {/*</TableCell>*/}
                {/*<TableCell*/}
                {/*  style={styleData.kgLarge}*/}
                {/*  align="center"*/}
                {/*  className="small-green"*/}
                {/*>*/}
                {/*  Short Bast Fiber*/}
                {/*</TableCell>*/}
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Hurd
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Dust
                </TableCell>
              </TableRow>
              {data?.resultAndCarbonFootPrint?.carbonFootPrint?.onProcess &&
              data?.resultAndCarbonFootPrint?.carbonFootPrint?.onProcess
                ?.length ? (
                data?.resultAndCarbonFootPrint?.carbonFootPrint?.onProcess
                  .sort(
                    (a: any, b: any) => parseInt(a.order) - parseInt(b.order)
                  )
                  .map((result: any, index: number) => {
                    const lcaOil = data?.lcaOilResult?.onProcess;
                    const lcaCake = data?.lcaCakeResult?.onProcess;
                    const lcaHull = data?.lcaHullResult?.onProcess;
                    const lcaBastFiber = data?.lcaBastFiberResult?.onProcess;
                    // const lcaLongBastFiber =
                    //   data?.lcaLongBastFiberesult?.onProcess;
                    // const lcaShortBastFiber =
                    //   data?.lcaShortBastFiberesult?.onProcess;
                    const lcaHurd = data?.lcaHurdResult?.onProcess;
                    const lcaDust = data?.lcaDustResult?.onProcess;
                    let tableColor = "#fff";
                    if (["EMISSIONSON-PROCESS"].includes(result?.key)) {
                      tableColor = CHART_COLOR.redColor;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            ...styleData.activitySticky,
                            background: tableColor,
                            textTransform: "capitalize",
                          }}
                        >
                          <Typography
                            component={"div"}
                            className="d-flex-a flex-sb"
                          >
                            <Typography>{result?.activities}</Typography>
                            <Typography>
                              {" "}
                              {result?.unit !== "kg CO2 eq/year"
                                ? ` ${result?.unit}%`
                                : ""}
                            </Typography>
                          </Typography>
                        </TableCell>
                        {/* <TableCell style={{ ...styleData.unitSticky, background: tableColor }}>
                          {result?.unit}
                        </TableCell> */}
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.valueSticky,
                            background: tableColor,
                          }}
                        >
                          {roundOfData(result?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgSmall,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaOil?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaCake?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaHull?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgLarge,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaBastFiber?.[index]?.value)}
                        </TableCell>
                        {/*<TableCell*/}
                        {/*  align="center"*/}
                        {/*  style={{*/}
                        {/*    ...styleData.kgLarge,*/}
                        {/*    background: tableColor,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {roundOfData1(lcaLongBastFiber?.[index]?.value)}*/}
                        {/*</TableCell>*/}
                        {/*<TableCell*/}
                        {/*  align="center"*/}
                        {/*  style={{*/}
                        {/*    ...styleData.kgLarge,*/}
                        {/*    background: tableColor,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {roundOfData1(lcaShortBastFiber?.[index]?.value)}*/}
                        {/*</TableCell>*/}
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaHurd?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaDust?.[index]?.value)}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <Typography />
              )}
              <TableRow>
                <TableCell
                  style={styleData.corbonSticky}
                  align="center"
                  className="ligt-green"
                >
                  POST-PROCESS
                </TableCell>
                <TableCell
                  style={styleData.valueSticky}
                  align="center"
                  className="ligt-green"
                >
                  Kg CO<sub>2</sub> eq/year
                </TableCell>
                <TableCell
                  style={styleData.kgSmall}
                  align="center"
                  className="small-green"
                >
                  Oil
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Meal
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Hull
                </TableCell>
                <TableCell
                  style={styleData.kgLarge}
                  align="center"
                  className="small-green"
                >
                  Bast Fiber
                </TableCell>
                {/*<TableCell*/}
                {/*  style={styleData.kgLarge}*/}
                {/*  align="center"*/}
                {/*  className="small-green"*/}
                {/*>*/}
                {/*  Long Bast Fiber*/}
                {/*</TableCell>*/}
                {/*<TableCell*/}
                {/*  style={styleData.kgLarge}*/}
                {/*  align="center"*/}
                {/*  className="small-green"*/}
                {/*>*/}
                {/*  Short Bast Fiber*/}
                {/*</TableCell>*/}
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Hurd
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Dust
                </TableCell>
              </TableRow>
              {data?.resultAndCarbonFootPrint?.carbonFootPrint?.postProcess &&
              data?.resultAndCarbonFootPrint?.carbonFootPrint?.postProcess
                ?.length ? (
                data?.resultAndCarbonFootPrint?.carbonFootPrint?.postProcess
                  .sort(
                    (a: any, b: any) => parseInt(a.order) - parseInt(b.order)
                  )
                  .map((result: any, index: number) => {
                    const lcaOil = data?.lcaOilResult?.postProcess;
                    const lcaCake = data?.lcaCakeResult?.postProcess;
                    const lcaHull = data?.lcaHullResult?.postProcess;
                    const lcaBastFiber = data?.lcaBastFiberResult?.postProcess;
                    // const lcaLongBastFiber =
                    //   data?.lcaLongBastFiberesult?.postProcess;
                    // const lcaShortBastFiber =
                    //   data?.lcaShortBastFiberesult?.postProcess;
                    const lcaHurd = data?.lcaHurdResult?.postProcess;
                    const lcaDust = data?.lcaDustResult?.postProcess;
                    let tableColor = "#fff";
                    if (["EMISSIONSPOST-PROCESS"].includes(result?.key)) {
                      tableColor = CHART_COLOR.redColor;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            ...styleData.activitySticky,
                            background: tableColor,
                            textTransform: "capitalize",
                          }}
                        >
                          {result?.activities}
                        </TableCell>
                        {/* <TableCell style={{ ...styleData.unitSticky, background: tableColor }}>
                          {result?.unit}
                        </TableCell> */}
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.valueSticky,
                            background: tableColor,
                          }}
                        >
                          {roundOfData(result?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgSmall,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaOil?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaCake?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaHull?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgLarge,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaBastFiber?.[index]?.value)}
                        </TableCell>
                        {/*<TableCell*/}
                        {/*  align="center"*/}
                        {/*  style={{*/}
                        {/*    ...styleData.kgLarge,*/}
                        {/*    background: tableColor,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {roundOfData1(lcaLongBastFiber?.[index]?.value)}*/}
                        {/*</TableCell>*/}
                        {/*<TableCell*/}
                        {/*  align="center"*/}
                        {/*  style={{*/}
                        {/*    ...styleData.kgLarge,*/}
                        {/*    background: tableColor,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {roundOfData1(lcaShortBastFiber?.[index]?.value)}*/}
                        {/*</TableCell>*/}
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaHurd?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                          }}
                        >
                          {roundOfData1(lcaDust?.[index]?.value)}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <Typography />
              )}
              <TableRow>
                <TableCell
                  style={styleData.corbonSticky}
                  align="center"
                  className="ligt-green"
                >
                  CARBON BALANCE
                </TableCell>
                <TableCell
                  style={styleData.valueSticky}
                  align="center"
                  className="ligt-green"
                >
                  Kg CO<sub>2</sub> eq/year
                </TableCell>
                <TableCell
                  style={styleData.kgSmall}
                  align="center"
                  className="small-green"
                >
                  Oil
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Meal
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Hull
                </TableCell>
                <TableCell
                  style={styleData.kgLarge}
                  align="center"
                  className="small-green"
                >
                  Bast Fiber
                </TableCell>
                {/*<TableCell*/}
                {/*  style={styleData.kgLarge}*/}
                {/*  align="center"*/}
                {/*  className="small-green"*/}
                {/*>*/}
                {/*  Long Bast Fiber*/}
                {/*</TableCell>*/}
                {/*<TableCell*/}
                {/*  style={styleData.kgLarge}*/}
                {/*  align="center"*/}
                {/*  className="small-green"*/}
                {/*>*/}
                {/*  Short Bast Fiber*/}
                {/*</TableCell>*/}
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Hurd
                </TableCell>
                <TableCell
                  style={styleData.kgMedium}
                  align="center"
                  className="small-green"
                >
                  Dust
                </TableCell>
              </TableRow>
              {data?.resultAndCarbonFootPrint?.carbonFootPrint?.corbonBalance &&
              data?.resultAndCarbonFootPrint?.carbonFootPrint?.corbonBalance
                ?.length ? (
                data?.resultAndCarbonFootPrint?.carbonFootPrint?.corbonBalance
                  .sort(
                    (a: any, b: any) => parseInt(a.order) - parseInt(b.order)
                  )
                  .map((result: any, index: number) => {
                    const lcaOil = data?.lcaOilResult?.corbonBalance;
                    const lcaCake = data?.lcaCakeResult?.corbonBalance;
                    const lcaHull = data?.lcaHullResult?.corbonBalance;
                    const lcaBastFiber =
                      data?.lcaBastFiberResult?.corbonBalance;
                    // const lcaLongBastFiber =
                    //   data?.lcaLongBastFiberesult?.corbonBalance;
                    // const lcaShortBastFiber =
                    //   data?.lcaShortBastFiberesult?.corbonBalance;
                    const lcaHurd = data?.lcaHurdResult?.corbonBalance;
                    const lcaDust = data?.lcaDustResult?.corbonBalance;
                    let tableColor = "#fff";
                    let extraStyle = {};
                    if (result?.key === "TOTALEMISSIONS") {
                      tableColor = CHART_COLOR.redColor;
                    } else if (result?.key === "CO2HEMPABSORPTION") {
                      tableColor = "rgb(91, 108, 91)";
                    } else if (result?.key === "netCorbon") {
                      tableColor = CHART_COLOR.greenColor;
                      extraStyle = {
                        fontSize: "14px !important",
                        fontWeight: "bold",
                      };
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            ...styleData.activitySticky,
                            background: tableColor,
                            ...extraStyle,
                            textTransform: "capitalize",
                          }}
                        >
                          {result?.activities}
                        </TableCell>
                        {/* <TableCell
                          style={{
                            ...styleData.unitSticky,
                            background: tableColor,
                            ...extraStyle,
                          }}>
                          {result?.unit}
                        </TableCell> */}
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.valueSticky,
                            background: tableColor,
                            ...extraStyle,
                          }}
                        >
                          {roundOfData(result?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgSmall,
                            background: tableColor,
                            ...extraStyle,
                          }}
                        >
                          {roundOfData(lcaOil?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                            ...extraStyle,
                          }}
                        >
                          {roundOfData(lcaCake?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                            ...extraStyle,
                          }}
                        >
                          {roundOfData(lcaHull?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgLarge,
                            background: tableColor,
                            ...extraStyle,
                          }}
                        >
                          {roundOfData(lcaBastFiber?.[index]?.value)}
                        </TableCell>
                        {/*<TableCell*/}
                        {/*  align="center"*/}
                        {/*  style={{*/}
                        {/*    ...styleData.kgLarge,*/}
                        {/*    background: tableColor,*/}
                        {/*    ...extraStyle,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {roundOfData(lcaLongBastFiber?.[index]?.value)}*/}
                        {/*</TableCell>*/}
                        {/*<TableCell*/}
                        {/*  align="center"*/}
                        {/*  style={{*/}
                        {/*    ...styleData.kgLarge,*/}
                        {/*    background: tableColor,*/}
                        {/*    ...extraStyle,*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {roundOfData(lcaShortBastFiber?.[index]?.value)}*/}
                        {/*</TableCell>*/}
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                            ...extraStyle,
                          }}
                        >
                          {roundOfData(lcaHurd?.[index]?.value)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            ...styleData.kgMedium,
                            background: tableColor,
                            ...extraStyle,
                          }}
                        >
                          {roundOfData(lcaDust?.[index]?.value)}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <Typography />
              )}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CorbonFootPrintWithLca;
