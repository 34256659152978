import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { myprofileSchema } from "utils/ValidatorSchema";
import { Controller, useForm } from "react-hook-form";
import { myProfile } from "types";
import CustomInput from "components/CustomInput/customInput";
import { getAllListData, updateData } from "services/CommonServices";
import { MASTER_ENDPOINT } from "utils/Constant";
import { useAppSelector } from "store/hooks";
import { toast } from "react-toastify";
import Loader from "components/Loader";

const Myprofile = () => {
  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });

  const [useEditDetail, setUseEditDetail] = React.useState(true);
  const [loader, setLoader] = React.useState(false);

  const { control, formState, setValue, handleSubmit, reset } = useForm<
    myProfile | any
  >({
    defaultValues: {
      firstName: "",
      lastName: "",
      address: "",
      phoneNumber: "",
      country: "",
      state: "",
      city: "",
      zip: "",
      email: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(myprofileSchema),
  });

  const getList = () => {
    setLoader(true);
    getAllListData(`${MASTER_ENDPOINT.user}?id=${userDetail.id}`)
      .then((res: any) => {
        const { firstName, lastName, login, address } = res.data[0];
        setValue("firstName", firstName ? firstName : "");
        setValue("lastName", lastName ? lastName : "");
        setValue("email", login.email ? login.email : "");
        setValue("address", address ? address : "");
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  useEffect(() => {
    getList();
  }, []);

  const cancelForm = () => {
    reset();
    getList();
    setUseEditDetail(true);
  };

  const submitForm = (data: any) => {
    setLoader(true);
    const postJson = {
      firstName: data["firstName"],
      email: data["email"],
      lastName: data["lastName"],
      organization: userDetail.organizationId,
      status: true,
      address: data["address"],
      roleId: userDetail.roleId,
      updatePassword: false,
    };
    updateData(userDetail.id, postJson, MASTER_ENDPOINT.user).then((resp) => {
      toast.success(
        resp && resp.message ? resp.message : "Updated successfully"
      );
      setUseEditDetail(true);
      setLoader(false);
    });
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={"div"}
          className="d-flex-a flex-sb bottom-border pb-24"
        >
          <Typography className="commontitle">My Profile</Typography>
          <Typography component={"div"} className="inline-flex">
            {useEditDetail ? (
              <Button
                className="cancel-btn btn-2r"
                onClick={() => setUseEditDetail(false)}
              >
                edit
              </Button>
            ) : (
              <>
                <Button className="cancel-btn btn-2r" onClick={cancelForm}>
                  Cancel
                </Button>
                <Button className="save-btn" onClick={handleSubmit(submitForm)}>
                  Save
                </Button>
              </>
            )}
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        {<Loader openLoader={loader} />}

        <form className="custom-form">
          {/* <fieldset disabled={useEditDetail}> */}
          <Typography component={"div"} className="form-box">
            <Grid container>
              <Grid item md={8} sm={12} xs={12}>
                <Typography className="commonsubtitle mb-24">
                  Personal Information
                </Typography>
                <Grid container columnSpacing={4} rowSpacing={2.5}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="firstName"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label="First Name *"
                            className="custom-input"
                            onChange={(e: any) => {
                              onChange(
                                e.target.value.replace(/[^A-Za-z]/g, "")
                              );
                            }}
                            value={value}
                            name={name}
                            disabled={useEditDetail}
                            error={formState.errors.firstName?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="lastName"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label="Last Name *"
                            className="custom-input"
                            onChange={(e: any) => {
                              onChange(
                                e.target.value.replace(/[^A-Za-z]/g, "")
                              );
                            }}
                            value={value}
                            name={name}
                            disabled={useEditDetail}
                            error={formState.errors.lastName?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography className="commonsubtitle mb-24 mt-24">
                  Contact Information
                </Typography>
                <Grid container columnSpacing={4} rowSpacing={2.5}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="address"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label="Address"
                            className="custom-input"
                            onChange={onChange}
                            isTextArea={true}
                            value={value}
                            disabled={useEditDetail}
                            minRows={4}
                            name={name}
                            error={formState.errors.address?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  {/* <Grid item md={6} sm={12}>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='city'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              label='City'
                              className='custom-input'
                              onChange={onChange}
                              value={value}
                              name={name}
                              error={formState.errors.city?.message}
                            />
                          );
                        }}
                      />
                    </Grid> */}
                  {/* <Grid item md={6} sm={12}>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='state'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              label='State'
                              className='custom-input'
                              onChange={onChange}
                              value={value}
                              name={name}
                              error={formState.errors.state?.message}
                            />
                          );
                        }}
                      />
                    </Grid> */}
                  {/* <Grid item md={6} sm={12}>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='zip'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              label='Zip Code'
                              className='custom-input'
                              onChange={onChange}
                              value={value}
                              name={name}
                              error={formState.errors.zip?.message}
                            />
                          );
                        }}
                      />
                    </Grid> */}
                  {/* <Grid item md={6} sm={12}>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='country'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              label='Country'
                              className='custom-input'
                              onChange={onChange}
                              value={value}
                              name={name}
                              error={formState.errors.country?.message}
                            />
                          );
                        }}
                      />
                    </Grid> */}
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="email"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label="Email *"
                            className="custom-input"
                            onChange={onChange}
                            value={value}
                            name={name}
                            error={formState.errors.email?.message}
                            disabled={useEditDetail}
                          />
                        );
                      }}
                    />
                  </Grid>

                  {/* <Grid item md={6} sm={12}>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='phoneNumber'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              label='Phone Number'
                              className='custom-input'
                              onChange={onChange}
                              value={value}
                              name={name}
                              error={formState.errors.phoneNumber?.message}
                            />
                          );
                        }}
                      />
                    </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Typography>
          {/* </fieldset> */}
        </form>
      </Grid>
    </Grid>
  );
};

export default Myprofile;
