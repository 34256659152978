import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { logger } from "redux-logger";
import { thunk } from "redux-thunk";

import { userLoginReducer } from "./reducers/UserReducer";

const rootReducer = combineReducers({
  userLogin: userLoginReducer,
});

const middlewareList: any = [thunk, logger];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewareList))
);

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
