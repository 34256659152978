import { jwtDecode } from "jwt-decode";
import { decrypt, encrypt } from "./LocalStorage";

type AuthTokenType = {
  token: string;
};

type MenuType = {
  menus: string;
};

class Storage {
  static KEYS = {
    AUTH_TOKEN: "AUTH_TOKEN",
    REFRESH_TOCKEN_ID: "REFRESH_TOCKEN_ID",
    MENUS: "MENUS",
    REMEMBER_ME: "REMEMBER_ME",
    ORGANIZATION_LOGO: "ORGANIZATION_LOGO",
  };

  static setItem = (key: string, value: string): void => {
    if (!value) {
      value = "";
    }

    if (typeof value !== "string") {
      value = JSON.stringify(value);
    }
    localStorage.setItem(encrypt(key), encrypt(value));
  };

  static getItem = (key: string, shouldParse?: boolean): string | null => {
    const keyVal = decrypt(localStorage.getItem(Storage.searchKey(key)));

    if (shouldParse && keyVal) {
      return JSON.parse(keyVal);
    }

    return keyVal;
  };

  static searchKey(key: string): string {
    const keys = Object.keys(localStorage);
    let i = keys.length;

    while (i--) {
      try {
        if (decrypt(keys[i]) === key) {
          return keys[i];
        }
      } catch (e) {
        localStorage.removeItem(keys[i]);
      }
    }

    return "";
  }

  static removeItem = (key: string): void => {
    localStorage.removeItem(Storage.searchKey(key));
  };

  static normalSetItem = (key: string, value: string): void => {
    localStorage.setItem(key, value);
  };

  static normalGetItem = (key: string) => {
    const keyVal = localStorage.getItem(key);
    return keyVal;
  };

  static clear = (): void => {
    localStorage.clear();
  };

  static getTokens = () => {
    try {
      const appAuth = Storage.getItem(Storage.KEYS.AUTH_TOKEN);
      return appAuth ? (JSON.parse(appAuth) as AuthTokenType) : null;
    } catch (e) {
      return null;
    }
  };

  static getMenus = () => {
    try {
      const appMenu = Storage.getItem(Storage.KEYS.MENUS);
      return appMenu ? (JSON.parse(appMenu) as MenuType) : null;
    } catch (e) {
      return null;
    }
  };

  static isTokenValidDetails = () => {
    const tokenData: any = Storage.getTokens();
    if (!tokenData || !tokenData.token) {
      return false;
    }
    const userData: any = jwtDecode(String(tokenData.token));
    if (!userData || (userData && userData.exp * 1000 < Date.now())) {
      return false;
    }
    return userData;
  };

  static updateAccessToken = (token: any) => {
    const tokenData: any = Storage.getTokens();
    tokenData.token = token.token;
    tokenData.refreshToken = token.refreshToken;
    Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
  };
}

export default Storage;
