import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ArrowDown from "assets/png/arrow_down.svg";
import { Link, useNavigate } from "react-router-dom";
import { PrivateRoutes } from "routes/routeConstans";
import { deleteData, getAllListData } from "services/CommonServices";
import { MASTER_ENDPOINT } from "utils/Constant";
import Pagination from "components/Pagination";
import { DefaultPerPage } from "utils/ArrayContans";
import TableLoader from "components/TableLoader";
import moment from "moment";
import { useAppSelector } from "store/hooks";
import Edit from "assets/png/edit.png";
import Delete from "assets/png/delete.png";
import ViewPng from "assets/png/view.png";
import ConfirmationModel from "components/ConfirmationModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Facilities = () => {
  const navigate: any = useNavigate();
  const [param, setParam] = useState<any>({
    page: 1,
    order: "asc",
    sortby: "name",
  });
  const [tableData, setTableData] = useState<any>({
    loader: false,
    total: 0,
    list: [],
  });
  const [deleteItem, setDeleteItem] = useState<any>({
    id: "",
    open: false,
  });

  const [deleteAllItem, setDeleteAllItem] = useState<any>({ open: false });

  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });

  useEffect(() => {
    getList();
  }, [param]);

  const getList = () => {
    setTableData({
      ...tableData,
      loader: true,
      list: [],
    });
    getAllListData(
      `${MASTER_ENDPOINT.Scenario}?skip=${
        param.page * DefaultPerPage - DefaultPerPage
      }&take=${DefaultPerPage}&order=${param.order}&sortby=${param.sortby}`
    ).then((res: any) => {
      setTableData({
        ...tableData,
        loader: false,
        list: res?.data,
        total: res?.total,
      });
    });
  };

  const handleClose = () => {
    setDeleteItem({
      id: "",
      open: false,
    });
  };

  const handleClose1 = () => {
    setDeleteAllItem({
      open: false,
    });
  };

  const handleDelete = () => {
    deleteData(deleteItem.id, MASTER_ENDPOINT.Scenario, true).then(() => {
      handleClose();
      getList();
    });
  };

  const handleAllDelete = () => {
    deleteData("", `${MASTER_ENDPOINT.Scenario}/truncate`, true).then(() => {
      handleClose1();
      getList();
    });
  };

  const editScenario = (row: any, parm: string) => {
    navigate(PrivateRoutes.SCENARIO.LIST + "/" + parm, { state: { row } });
  };

  const sortByFunction = (name: string) => {
    if (param.sortby === name) {
      setParam({
        ...param,
        order: param.order === "asc" ? "desc" : "asc",
      });
    } else {
      setParam({
        ...param,
        sortby: name,
        order: "asc",
      });
    }
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={"div"} className="d-flex-a flex-sb pb-24">
          <Typography className="commontitle">My Scenarios</Typography>
          {userDetail.roleId !== 4 && (
            <Typography>
              <Link
                to={PrivateRoutes.SCENARIO.LIST + "/add"}
                style={{ marginRight: "5px" }}
              >
                <Button
                  className="save-btn"
                  startIcon={<AddIcon sx={{ fill: "#ffffff" }} />}
                >
                  My Scenario
                </Button>
              </Link>
              {tableData?.list?.length > 0 && (
                <Button
                  className="cancel-btn-hover"
                  startIcon={<DeleteForeverIcon />}
                  onClick={() => setDeleteAllItem({ open: true })}
                >
                  Delete All
                </Button>
              )}
            </Typography>
          )}
        </Typography>
      </Grid>

      <Grid item md={12} sm={12} xs={12}>
        <Typography component={"div"} className="custom-table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="w-20">
                    <Typography component={"div"} className="d-flex-a">
                      <Typography
                        component={"div"}
                        className="arrow-box"
                        onClick={() => sortByFunction("name")}
                      >
                        <img src={ArrowDown} alt="arrow" className="d-arrow" />
                        <img src={ArrowDown} alt="arrow" className="u-arrow" />
                      </Typography>
                      <Typography className="th-title">Name</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className="w-15">
                    <Typography component={"div"} className="d-flex-a">
                      <Typography
                        component={"div"}
                        className="arrow-box"
                        onClick={() => sortByFunction("date")}
                      >
                        <img src={ArrowDown} alt="arrow" className="d-arrow" />
                        <img src={ArrowDown} alt="arrow" className="u-arrow" />
                      </Typography>
                      <Typography className="th-title">Created Date</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className="w-40">
                    <Typography component={"div"} className="d-flex-a">
                      {/* <Typography component={'div'} className='arrow-box'>
                        <img src={ArrowDown} alt='arrow' className='d-arrow' />
                        <img src={ArrowDown} alt='arrow' className='u-arrow' />
                      </Typography> */}
                      <Typography className="th-title">Info</Typography>
                    </Typography>
                  </TableCell>
                  <TableCell className="w-15">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.loader ? (
                  <TableLoader colsSpan={5} />
                ) : tableData?.list.length ? (
                  tableData?.list.map((row: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {moment(row.createdAt).utc().format("MMM DD, YYYY")}
                      </TableCell>
                      <TableCell>{row.comments}</TableCell>
                      <TableCell>
                        <Typography component={"div"} className="d-flex-a">
                          <img
                            src={Edit}
                            alt="edit"
                            className="mr-10"
                            onClick={() => editScenario(row, "edit")}
                          />
                          <img
                            src={ViewPng}
                            alt="view"
                            style={{ width: 20, height: 20 }}
                            className="mr-10"
                            onClick={() => editScenario(row, "view")}
                          />
                          <img
                            src={Delete}
                            alt="edit"
                            onClick={() =>
                              setDeleteItem({
                                id: row._id,
                                open: true,
                              })
                            }
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      <Typography component={"div"} className="noRecordsText">
                        No Records Found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            total={tableData.total}
            page={param.page}
            changePagination={(e: any, page: number) =>
              setParam({ ...param, page: page })
            }
          />
        </Typography>
      </Grid>
      {deleteItem.open && (
        <ConfirmationModel
          onClose={handleClose}
          onSubmit={handleDelete}
          heading={"Delete"}
          btnName2="Delete"
          subHeading={"Are you sure you want to Delete this Scenario?"}
        />
      )}
      {deleteAllItem.open && (
        <ConfirmationModel
          onClose={handleClose1}
          onSubmit={handleAllDelete}
          heading={"Delete All"}
          btnName2="Delete All"
          subHeading={"Are you sure you want to delete all this scenarios?"}
        />
      )}
    </Grid>
  );
};

export default Facilities;
