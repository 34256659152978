export const MASTER_ENDPOINT = {
  Login: "auth/login",
  Changepassword: "password/change",
  Organization: "organization",
  user: "users",
  facility: "facilities",
  Standards: "standards",
  Usertype: "master/role",
  Scenario: "scenario",
  Dashboard: "dashboard",
  files: "organization/logo",
  customers: "customers",
};

export const CHART_COLOR = {
  scope1: "#F0EEBE",
  scope2: "#CFEFEE",
  scope3: "#FFA5AA",
  corbonBalance: "#7CDFFF",
  co2Hemp: "#50C9BA",
  redColor: "#DA627D",
  greenColor: "#23E8CF",
};
