import { FormHelperText, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";

interface SelectProps {
  onChange?: (src: any) => void;
  value?: any;
  placeHolder?: string;
  options?: any;
  labelKey: string;
  valueKey: string;
  id?: string;
  name?: string;
  ref?: any;
  error?: any;
  disabled?: boolean;
}

const CustomSelect = (props: SelectProps) => {
  const {
    options,
    onChange,
    value,
    error,
    labelKey,
    valueKey,
    id,
    name,
    ref,
    disabled,
  } = props;
  return (
    <>
      <Select
        labelId="demo-simple-select-label"
        IconComponent={KeyboardArrowDownIcon}
        id={id}
        name={name}
        ref={ref}
        value={value}
        label="Age"
        disabled={disabled}
        error={error}
        onChange={onChange}
      >
        {options.length ? (
          options.map((item: any, index: number) => {
            return (
              <MenuItem
                key={index}
                value={item[valueKey]}
                selected={String(item[valueKey]) === String(value)}
              >
                {item[labelKey]}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={""} disabled>
            No Items
          </MenuItem>
        )}
      </Select>
      {error && (
        <FormHelperText style={{ color: "#d32f2f" }}>{error}</FormHelperText>
      )}
    </>
  );
};

export default CustomSelect;
