import React from "react";
import Login from "pages/public/Login";
import { Navigate, Route, Routes } from "react-router-dom";

import { PublicRoutes as PublicRoutesDirectives } from "./routeConstans";

const PublicRoutes = () => {
  const { LOGIN } = PublicRoutesDirectives;
  return (
    <Routes>
      <Route path="/" element={<Navigate to={"/login"} replace />} />
      <Route path={LOGIN} element={<Login />} />
      <Route path="*" element={<Navigate to={"/login"} />} />
    </Routes>
  );
};

export default PublicRoutes;
