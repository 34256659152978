import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { facilitiesSchema } from "utils/ValidatorSchema";
import { facilityForm } from "types";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PrivateRoutes } from "routes/routeConstans";
import CustomInput from "components/CustomInput/customInput";
import CustomSelect from "components/CustomSelect";
import { FacilityType } from "utils/ArrayContans";
import {
  addNewData,
  getAllListData,
  updateData,
} from "services/CommonServices";
import { MASTER_ENDPOINT } from "utils/Constant";
import Loader from "components/Loader";
import { useAppSelector } from "store/hooks";

const FacilityAdd = () => {
  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });
  const [loader, setLoader] = React.useState(false);
  const param: any = useParams();
  const navigate = useNavigate();
  const { handleSubmit, control, formState, setValue } = useForm<
    facilityForm | any
  >({
    defaultValues: {
      name: "",
      type: "",
      address: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(facilitiesSchema),
  });

  const submitForm = (data: any) => {
    setLoader(true);
    if (param.add !== "add") {
      data["status"] = true;
      updateData(param.add, data, MASTER_ENDPOINT.facility, true)
        .then(() => {
          setLoader(false);
          navigate(PrivateRoutes.FACILITIES.LIST);
        })
        .catch((e) => {
          console.log(e);
          setLoader(false);
        });
    } else {
      data["status"] = true;
      addNewData(data, MASTER_ENDPOINT.facility, "", true)
        .then(() => {
          setLoader(false);
          navigate(PrivateRoutes.FACILITIES.LIST);
        })
        .catch((e) => {
          console.log(e);
          setLoader(false);
        });
    }
  };

  const getFaciltySingleData = (id: string) => {
    setLoader(true);
    getAllListData(`${MASTER_ENDPOINT.facility}?id=${id}`).then((resp: any) => {
      if (resp?.data && resp?.data?.[0]) {
        const ObjectItem = resp?.data?.[0];
        setValue("name", ObjectItem?.name);
        setValue("type", ObjectItem?.type);
        setValue("address", ObjectItem?.address);
        // setValue('countryCode', ObjectItem?.countryCode);
        // setValue('phoneNumber', ObjectItem?.phoneNumber);
        // setValue('country', ObjectItem?.country);
        // setValue('state', ObjectItem?.state);
        // setValue('city', ObjectItem?.city);
        // setValue('zip', ObjectItem?.zip);
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    if (param.add !== "add") {
      getFaciltySingleData(param.add);
    }
  }, [param]);

  return (
    <Grid container>
      <Loader openLoader={loader} />
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={"div"}
          className="d-flex-a flex-sb bottom-border pb-24"
        >
          <Typography className="commontitle">
            {param.add === "add" ? "Add" : "Edit"} Facility
          </Typography>
          <Typography component={"div"} className="inline-flex">
            <Link to={PrivateRoutes.FACILITIES.LIST}>
              <Button className="cancel-btn btn-2r">Cancel</Button>
            </Link>
            <Button className="save-btn" onClick={handleSubmit(submitForm)}>
              Save
            </Button>
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={"div"} className="form-box">
          <Grid container>
            <Grid item md={7.5} sm={12} xs={12}>
              <form className="custom-form">
                <Grid container columnSpacing={4} rowSpacing={2.5}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="name"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label="Facility Name *"
                            className="custom-input"
                            onChange={onChange}
                            value={value}
                            name={name}
                            error={formState.errors.name?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <FormControl fullWidth className="custom-input">
                      <InputLabel id="demo-simple-select-label">
                        Facility Type *
                      </InputLabel>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="type"
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              onChange={onChange}
                              value={value}
                              name={name}
                              error={formState.errors.type?.message}
                              options={FacilityType}
                              labelKey="label"
                              valueKey="value"
                              disabled={
                                param.add === "add"
                                  ? false
                                  : userDetail.roleId === 1
                                  ? false
                                  : true
                              }
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="address"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label="Address"
                            className="custom-input"
                            isTextArea={true}
                            onChange={onChange}
                            value={value}
                            name={name}
                            minRows={4}
                            maxRows={4}
                            error={formState.errors.address?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  {/* <Grid item md={6} sm={12}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='city'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label='City'
                            className='custom-input'
                            onChange={onChange}
                            value={value}
                            name={name}
                            error={formState.errors.city?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='state'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label='State'
                            className='custom-input'
                            onChange={onChange}
                            value={value}
                            name={name}
                            error={formState.errors.state?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='zip'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label='ZIP code'
                            className='custom-input'
                            onChange={onChange}
                            value={value}
                            name={name}
                            type={'number'}
                            error={formState.errors.zip?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='country'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label='Country'
                            className='custom-input'
                            onChange={onChange}
                            value={value}
                            name={name}
                            error={formState.errors.country?.message}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Controller
                      control={control}
                      defaultValue=''
                      name='phoneNumber'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label='Phone Number'
                            className='custom-input'
                            onChange={onChange}
                            value={value}
                            name={name}
                            type={'number'}
                            error={formState.errors.phoneNumber?.message}
                          />
                        );
                      }}
                    />
                  </Grid> */}
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FacilityAdd;
