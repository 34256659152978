import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PrivateRoutes } from "routes/routeConstans";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { customerForm } from "types";
import { customerSchema } from "utils/ValidatorSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "components/CustomInput/customInput";
import {
  addNewData,
  updateData,
  getAllListData,
} from "services/CommonServices";
import { MASTER_ENDPOINT } from "utils/Constant";
import _ from "lodash";
import { useAppSelector } from "store/hooks";
import Loader from "components/Loader";
import orgoLogo from "../../../../assets/png/orgoLogo.png";
import { toast } from "react-toastify";

const AddCustomer = () => {
  const [editRow, setEditRow] = useState<any>({});
  const [loader, setLoader] = React.useState(false);
  const { userInfo } = useAppSelector((store: any) => store.userLogin);

  const [customerData, setCustomerData] = useState<any>({});
  const [customerLogo, setCustomerLogo] = useState<any>("");
  const [localImage, setLocalImage] = React.useState<any>("");

  const location = useLocation();
  const navigate = useNavigate();
  const { LIST } = PrivateRoutes.CUSTOMERS;

  const { handleSubmit, control, formState, reset, setValue, setError } =
    useForm<customerForm>({
      defaultValues: {
        name: "",
        email: "",
        address: "",
        phoneNumber: "",
      },
      reValidateMode: "onChange",
      resolver: yupResolver<any>(customerSchema),
    });

  const submitForm = (data: any) => {
    if (customerLogo?.name) {
      const files = customerLogo?.name.split(".").pop();
      if (!["jpeg", "png", "jpg"].includes(files)) {
        toast.error("You can Upload only jpeg, jpg, png");
        return true;
      }
    }

    const formData = new FormData();
    if (customerLogo.name) {
      formData.append("file", customerLogo);
    }
    formData.append("name", data["name"]);
    formData.append("organization", userInfo?.organizationId);
    formData.append("email", data["email"]);
    formData.append("address", data["address"]);
    formData.append("phoneNumber", data["phoneNumber"]);

    addNewData(
      formData,
      MASTER_ENDPOINT.customers,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      true
    )
      .then(() => {
        navigate("/customers");
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
    setLoader(false);
  };

  useEffect(() => {
    //setLoader(true);
    if (!_.isEmpty(location.state)) {
      // console.log(location.state);
      const editData = location.state.row;
      setEditRow(editData);
      setCustomerData(editData);

      setValue("name", editData["name"]);
      setValue("address", editData["address"]);
      setValue("email", editData["email"]);
      setValue("phoneNumber", editData["phoneNumber"]);

      //setLoader(false);
    }
  }, [location.state]);

  return (
    <Grid container>
      <Loader openLoader={loader} />
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={"div"}
          className="d-flex-a flex-sb bottom-border pb-24"
        >
          <Typography className="commontitle">
            {!_.isEmpty(editRow) ? "Edit customers" : "Add customers"}
          </Typography>
          <Typography component={"div"} className="inline-flex">
            <Link to={LIST}>
              <Button className="cancel-btn btn-2r" onClick={() => reset()}>
                Cancel
              </Button>
            </Link>
            <Button className="save-btn" onClick={handleSubmit(submitForm)}>
              Save
            </Button>
          </Typography>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={"div"}
          className="form-box"
          sx={{ padding: "24px !important" }}
        >
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <form className="custom-form">
                <Grid container columnSpacing={4} rowSpacing={2.5}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="name"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label="Name *"
                            className="custom-input"
                            onChange={onChange}
                            ref={ref}
                            value={value}
                            name={name}
                            error={formState?.errors?.name?.message}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="email"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            label="Email Address *"
                            className="custom-input"
                            onChange={onChange}
                            ref={ref}
                            value={value}
                            name={name}
                            error={formState?.errors?.email?.message}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="address"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label="Address"
                            className="custom-input"
                            onChange={onChange}
                            isTextArea={true}
                            value={value}
                            minRows={4}
                            name={name}
                            error={formState.errors.address?.message}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={6} sm={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="phoneNumber"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            label="Phone Number"
                            className="custom-input"
                            onChange={onChange}
                            value={value}
                            name={name}
                            error={formState.errors.phoneNumber?.message}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={12} sm={12} xs={12} className="flex-column">
                    <Typography>Logo</Typography>

                    <img
                      className="org-profile"
                      src={
                        localImage
                          ? localImage
                          : customerData?.logo?._id
                          ? `${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.files}?id=${customerData?.logo?._id}`
                          : orgoLogo
                      }
                      alt="IndHemp"
                    />

                    <Button className="org-btn" component="label">
                      Upload Picture
                      <input
                        accept="image/*"
                        hidden
                        type="file"
                        onChange={(e: any) => {
                          if (e.target.files[0]) {
                            setCustomerLogo(e.target.files[0]);
                            setLocalImage(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }
                        }}
                      />
                    </Button>

                    {/* <Typography className='org-remove'>Remove picture</Typography> */}
                    <Typography className="org-support">
                      supported formats : jpeg, jpg, png{" "}
                    </Typography>
                    <Typography className="org-support">
                      Maximum size : 5MB
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddCustomer;
